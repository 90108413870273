import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import type { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { App, Credentials } from 'realm-web';
import { envConfig } from '@app/config/env';

const realmApp = new App({ id: envConfig.mongoAppName });

export const useApolloClient = () => {
  const getValidAccessToken = async () => {
    if (!realmApp.currentUser) {
      await realmApp.logIn(Credentials.apiKey(envConfig.realmApiKey));
    } else {
      await realmApp.currentUser.refreshAccessToken();
    }

    return realmApp.currentUser?.accessToken;
  };

  const httpLink = new HttpLink({
    uri: `https://us-east-2.aws.realm.mongodb.com/api/client/v2.0/app/${envConfig.mongoAppName}/graphql`,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      return fetch(uri, {
        ...options,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
  });

  const link = onError(({ networkError }) => {
    if ((networkError as LinkNetworkError)?.statusCode === 401)
      getValidAccessToken();
  });

  const apollo = new ApolloClient({
    link: link.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return apollo;
};

type LinkNetworkError = NetworkError & {
  statusCode: 401 | 403 | 405;
};
