import type { QueryClientConfig } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';

const ONE_HOUR_IN_MS = 60 * 60 * 1000;

export const queryClientDefaultOptions: QueryClientConfig['defaultOptions'] = {
  queries: {
    staleTime: ONE_HOUR_IN_MS,
    retryDelay: 0,
    refetchOnWindowFocus: false,
  },
};

export const queryClient = new QueryClient({
  defaultOptions: queryClientDefaultOptions,
});
