type GTagEvent = {
  action: string;
  category: string;
  label: string;
  value: number;
};

export const pageview = (url: string): void => {
  window.gtag('config', process.env.GA_TRACKING_ID, { page_path: url });
};

export const event = ({ action, category, label, value }: GTagEvent): void => {
  window.gtag('event', action, {
    value,
    event_category: category,
    event_label: label,
  });
};
