export const envConfig = {
  appUrl: process.env.NEXT_PUBLIC_APP_URL || '',
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isMirageEnabled: process.env.NEXT_PUBLIC_MIRAGE_SERVER === 'true',
  mongoAppName: process.env.NEXT_PUBLIC_MONGO_APP_NAME as string,
  realmApiKey: process.env.NEXT_PUBLIC_REALM_API_KEY as string,
  graphcms: {
    endpoint: process.env.NEXT_PUBLIC_CMS as string,
  },
  localStorage: {
    selectedRowsKey: 'selected-rows',
  },
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
};
