import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useState, useContext } from 'react';
import type { Fundamentalist } from '@app/interfaces/Fundamentalist';
import type { RecommendedWallet } from '@app/interfaces/RecommendedWallet';
import type { SalesforceLead } from '@app/interfaces/SalesforceLead';

type WalletProviderProps = {
  children: ReactNode;
};

type WalletContextProps = {
  leadInfo: SalesforceLead;
  setLeadInfo: Dispatch<SetStateAction<SalesforceLead>>;
  recommended: RecommendedWallet[];
  tickers: Fundamentalist[];
  customerWallet: RecommendedWallet[];
  setTickers: Dispatch<SetStateAction<Fundamentalist[]>>;
  setRecommended: Dispatch<SetStateAction<RecommendedWallet[]>>;
  setCustomerWallet: Dispatch<SetStateAction<RecommendedWallet[]>>;
  searchTicker: (search: string) => Fundamentalist[];
};

export const WalletContext = createContext({} as WalletContextProps);

export const WalletProvider = ({ children }: WalletProviderProps) => {
  const [recommended, setRecommended] = useState<RecommendedWallet[]>([]);
  const [tickers, setTickers] = useState<Fundamentalist[]>([]);
  const [customerWallet, setCustomerWallet] = useState<RecommendedWallet[]>([]);
  const [leadInfo, setLeadInfo] = useState({} as SalesforceLead);

  const searchTicker = (search: string) => {
    const searchRegExArray = search
      .split(' ')
      .map((word) => new RegExp(word.toLowerCase(), 'g'));

    return (
      tickers?.filter((stock) => {
        if (!stock) return false;
        const { ticker, empresa } = stock;

        return [ticker, empresa].some(
          (str) =>
            str &&
            searchRegExArray.every(
              (regex) => RegExp(regex).exec(str.toLowerCase())?.length
            )
        );
      }) || []
    );
  };

  return (
    <WalletContext.Provider
      value={{
        leadInfo,
        setLeadInfo,
        customerWallet,
        recommended,
        searchTicker,
        setRecommended,
        tickers,
        setCustomerWallet,
        setTickers,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWalletContext = (): WalletContextProps =>
  useContext(WalletContext);
